<template>
  <b-container
    class="mb-5"
    fluid
  >

    <div class="d-flex justify-content-end mb-3">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2 px-2">
        <b-col
          cols="12"
          md="3"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_template"
            >
              <strong>
                Template
              </strong>
            </label>
            <b-select
              id="filter_template"
              v-model="filter.template"
              :options="filterTemplates"
              :disabled="state.busy || fetching.filter.templates"
              @change="onRefreshTableItems"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="9"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_item"
            >
              <strong>
                Categories
              </strong>
            </label>
            <v-select
              id="filter_item"
              v-model="selected.categories"
              :options="list.filter.categories"
              type="text"
              label="category_name"
              placeholder="search item"
              multiple
              :disabled="state.busy || fetching.filter.categories || filter.template === 'All'"
              :loading="fetching.filter.categories"
            >
              <template #option="{ category_name }">
                <div class="d-flex flex-column">
                  <strong>{{ category_name }}</strong>
                </div>
              </template>
              <template #no-options="">
                no available item
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="9"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              id="filter_search"
              v-model="tableItems.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_status"
            >
              <strong>
                Status
              </strong>
            </label>
            <b-select
              id="filter_status"
              v-model="filter.status"
              :options="filterStatuses"
              :disabled="state.busy || fetching.filter.statuses"
              @change="onRefreshTableItems"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="px-1"
        >
          <b-button
            type="button"
            variant="primary"
            @click="onShowAddFormItem"
          >
            Add Item
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="tableItems"
        hover
        responsive
        show-empty
        class="mt-2"
        :per-page="tableItems.perPage"
        :current-page="tableItems.currentPage"
        :items="myProvider"
        :fields="tableItems.fields"
        :sort-by.sync="tableItems.sortBy"
        :sort-desc.sync="tableItems.sortDesc"
        :sort-direction="tableItems.sortDirection"
        :filter="tableItems.filter"
        :filter-included-fields="tableItems.filterOn"
      >
        <template #cell(index)="row">
          {{ tableItems.currentPage * tableItems.perPage - tableItems.perPage + (row.index + 1) }}
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              class="mr-1"
              @click="onShowEditFormItem(row.item)"
            >
              Edit
            </b-button>
            <b-button
              size="sm"
              class="mr-1"
              variant="primary"
              @click="onShowEditFormItemStatus(row.item)"
            >
              Status
            </b-button>
          </div>
        </template>

        <template #cell(item_code)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell(serial_number)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell(employee_name)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell()="row">
          <div class="text-nowrap">
            {{ row.value }}
          </div>
        </template>

        <template #cell(active)="row">
          {{ row.item.active == 1 ? "Yes" : "No" }}
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableItems.perPage"
              :options="tableItems.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableItems.currentPage"
            :total-rows="tableItems.totalRows"
            :per-page="tableItems.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-item"
      size="xl"
      scrollable
      no-close-on-backdrop
      :title="modalItemTitle"
      aria-hidden="false"
      @ok="onValidateFormItem"
    >
      <ValidationObserver
        ref="formItem"
      >
        <form
          novalidate
          @submit.prevent
        >
          <b-row>

            <b-col
              cols="12"
            >
              <b-row>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="template"
                    vid="template"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="template">
                        <strong>
                          Template
                        </strong>
                      </label>
                      <b-form-select
                        id="template"
                        v-model="item.template"
                        :options="list.item.templates"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy || fetching.item.templates"
                        @change="getItemTypeLists"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- select template --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12 d-none d-md-block">
                  <hr>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label
                      for="item_code"
                    >
                      <strong>
                        Item Code
                      </strong>
                    </label>
                    <b-input
                      id="item_code"
                      v-model="item.item_code"
                      type="text"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="type"
                    vid="type"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="type">
                        <strong>
                          Type
                        </strong>
                      </label>
                      <b-form-select
                        id="type"
                        v-model="item.type"
                        :options="list.item.types"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy || fetching.item.types"
                        @change="getItemCategoryLists"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- select type --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="category"
                    vid="category"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="category">
                        <strong>
                          Category
                        </strong>
                      </label>
                      <b-form-select
                        id="category"
                        v-model="item.category"
                        :options="list.item.categories"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy || fetching.item.categories"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- select category --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="serial number"
                vid="serial_number"
                rules="required|max:100"
              >
                <b-form-group>
                  <label
                    for="serial_number"
                  >
                    <strong>
                      Serial Number
                    </strong>
                  </label>
                  <b-input
                    id="serial_number"
                    v-model="item.serial_number"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter serial number"
                    autocomplete="off"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="8"
            >
              <ValidationProvider
                #default="{ errors }"
                name="model name"
                vid="item_name"
                rules="required|max:100"
              >
                <b-form-group>
                  <label
                    for="item_name"
                  >
                    <strong>
                      Model
                    </strong>
                  </label>
                  <b-input
                    id="item_name"
                    v-model="item.item_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter name"
                    autocomplete="off"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="mac address"
                vid="mac_address"
              >
                <b-form-group>
                  <label
                    for="mac_address"
                  >
                    <strong>
                      Mac Address
                    </strong>
                  </label>
                  <b-input
                    id="mac_address"
                    v-model="item.mac_address"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter mac address"
                    autocomplete="off"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="8"
            >
              <ValidationProvider
                #default="{ errors }"
                name="device name"
                vid="device_name"
                rules="max:100"
              >
                <b-form-group>
                  <label
                    for="device_name"
                  >
                    <strong>
                      Device Name
                    </strong>
                  </label>
                  <b-input
                    id="device_name"
                    v-model="item.device_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter device name"
                    autocomplete="off"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12 d-none d-md-block">
              <hr>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="brand"
                vid="brand"
                rules="required"
              >
                <b-form-group>
                  <label for="brand">
                    <strong>
                      Brand
                    </strong>
                  </label>
                  <v-select
                    id="brand"
                    v-model="selected.brand"
                    type="text"
                    label="brand_name"
                    placeholder="search brand here"
                    :class="[errors.length > 0 ? 'is-invalid' : null]"
                    :options="list.item.brands"
                    :disabled="state.busy || fetching.item.brands"
                    :loading="fetching.item.brands"
                  >
                    <template #option="{ brand_name }">
                      <div class="d-flex flex-column">
                        <strong>{{ brand_name }}</strong>
                      </div>
                    </template>
                    <template #no-options="">
                      no available brand
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="8"
            >
              <ValidationProvider
                #default="{ errors }"
                name="supplier"
                vid="supplier"
                rules="required"
              >
                <b-form-group>
                  <label for="supplier">
                    <strong>
                      Supplier
                    </strong>
                  </label>
                  <v-select
                    id="supplier"
                    v-model="selected.supplier"
                    type="text"
                    label="supplier_name"
                    placeholder="search supplier here"
                    :class="[errors.length > 0 ? 'is-invalid' : null]"
                    :options="list.item.suppliers"
                    :disabled="state.busy || fetching.item.suppliers"
                    :loading="fetching.item.suppliers"
                  >
                    <template #option="{ supplier_name, supplier_address }">
                      <div class="d-flex flex-column">
                        <strong>{{ supplier_name }}</strong>
                        <span>{{ supplier_address }}</span>
                      </div>
                    </template>
                    <template #no-options="">
                      no available supplier
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="sap asset code"
                vid="sap_asset_code"
              >
                <b-form-group>
                  <label
                    for="sap_asset_code"
                  >
                    <strong>
                      SAP Asset Code
                    </strong>
                  </label>
                  <b-input
                    id="sap_asset_code"
                    v-model="item.sap_asset_code"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter sap asset code"
                    autocomplete="off"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="purchase date"
                vid="purchase_date"
                rules="required"
              >
                <b-form-group>
                  <label for="purchase_date">
                    <strong>
                      Purchase Date
                    </strong>
                  </label>
                  <b-input
                    id="purchase_date"
                    v-model="item.purchase_date"
                    type="date"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter purchase date"
                    autocomplete="off"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="original_cost"
                vid="original_cost"
                rules="required"
              >
                <b-form-group>
                  <label for="original_cost">
                    <strong>
                      Cost
                    </strong>
                  </label>
                  <b-input
                    id="original_cost"
                    v-model="item.original_cost"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter cost"
                    autocomplete="off"
                    step="0.01"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="specifications"
                vid="specifications"
                rules="max:1000"
              >
                <b-form-group>
                  <label for="specifications">
                    <strong>
                      Specifications
                    </strong>
                  </label>
                  <b-textarea
                    id="specifications"
                    v-model="item.specifications"
                    rows="6"
                    max-rows="12"
                    placeholder="enter specifications"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <div
                v-if="state.editing"
                class="form-group"
              >
                <b-form-checkbox
                  id="is_active"
                  v-model="item.active"
                  name="is_active"
                  value="1"
                  unchecked-value="0"
                  :disabled="state.busy"
                  switch
                >
                  Is Active?
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? "Update Record" : "Save Record" }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-item-status"
      size="xl"
      scrollable
      no-close-on-backdrop
      title="Item Status"
      aria-hidden="false"
      @ok="onValidateFormItemStatus"
    >
      <ValidationObserver
        ref="formItemStatus"
      >
        <form
          role="formItemStatus"
          novalidate
          @submit.prevent
        >
          <b-row
            v-if="state.editing"
          >
            <b-col
              cols="12"
            >
              <b-row>

                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label
                      for="template_name"
                    >
                      <strong>
                        Template
                      </strong>
                    </label>
                    <b-input
                      id="template_name"
                      v-model="itemStatus.template_name"
                      type="text"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12 d-none d-md-block">
                  <hr>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label
                      for="item_code"
                    >
                      <strong>
                        Item Code
                      </strong>
                    </label>
                    <b-input
                      id="item_code"
                      v-model="itemStatus.item_code"
                      type="text"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label
                      for="type_name"
                    >
                      <strong>
                        Type
                      </strong>
                    </label>
                    <b-input
                      id="type_name"
                      v-model="itemStatus.type_name"
                      type="text"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label
                      for="category_name"
                    >
                      <strong>
                        Category
                      </strong>
                    </label>
                    <b-input
                      id="category_name"
                      v-model="itemStatus.category_name"
                      type="text"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="serial_number"
                >
                  <strong>
                    Serial Number
                  </strong>
                </label>
                <b-input
                  id="serial_number"
                  v-model="itemStatus.serial_number"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="8"
            >
              <b-form-group>
                <label
                  for="item_name"
                >
                  <strong>
                    Name
                  </strong>
                </label>
                <b-input
                  id="item_name"
                  v-model="itemStatus.item_name"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col cols="12 d-none d-md-block">
              <hr>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="original_cost"
                >
                  <strong>
                    Original Cost
                  </strong>
                </label>
                <b-input
                  id="original_cost"
                  v-model="itemStatus.original_cost"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="status"
                vid="status"
                rules="required"
              >
                <b-form-group>
                  <label for="status">
                    <strong>
                      Status
                    </strong>
                  </label>
                  <b-form-select
                    id="status"
                    v-model="itemStatus.status"
                    :options="list.item.statuses"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || fetching.item.statuses"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- select status --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              v-if="itemStatus.costable"
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="status cost"
                vid="status_cost"
                rules="required"
              >
                <b-form-group>
                  <label for="status_cost">
                    <strong>
                      Cost
                    </strong>
                  </label>
                  <b-input
                    id="status_cost"
                    v-model="itemStatus.status_cost"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter cost"
                    autocomplete="off"
                    step="0.01"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="max:500"
              >
                <b-form-group>
                  <label for="remarks">
                    <strong>
                      Remarks
                    </strong>
                  </label>
                  <b-textarea
                    id="remarks"
                    v-model="itemStatus.remarks"
                    rows="6"
                    max-rows="8"
                    placeholder="enter remarks"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          Update Status
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import {
  AdminItemService,
  SharedListService
} from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminItems',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Items'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      fetching: {
        filter: {
          templates: false,
          categories: false,
          statuses: false
        },
        item: {
          templates: false,
          types: false,
          categories: false,
          suppliers: false,
          brands: false,
          statuses: false
        }
      },
      filter: {
        template: 'All',
        status: 'All'
      },
      list: {
        filter: {
          templates: [],
          categories: [],
          statuses: []
        },
        item: {
          templates: [],
          types: [],
          categories: [],
          suppliers: [],
          brands: [],
          statuses: []
        }
      },
      selected: {
        categories: [],
        brand: null,
        supplier: null
      },
      item: {
        id: null,
        template: null,
        template_name: null,
        type: null,
        type_name: null,
        category: null,
        category_name: null,
        supplier: null,
        item_code: null,
        item_name: null,
        serial_number: null,
        purchase_date: null,
        brand: null,
        original_cost: 0.00,
        specifications: null,
        device_name: null,
        mac_address: null,
        sap_asset_code: null,
        active: 0
      },
      itemStatus: {
        id: null,
        template_name: null,
        type_name: null,
        category_name: null,
        item_code: null,
        item_name: null,
        serial_number: null,
        original_cost: 0.00,
        status: null,
        status_cost: 0.00,
        costable: false,
        remarks: null
      },
      tableItems: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'actions', class: 'text-center' },
          { key: 'item_code', class: 'text-center' },
          { key: 'category_name', label: 'category' },
          { key: 'serial_number' },
          { key: 'item_name' },
          { key: 'device_name' },
          { key: 'mac_address' },
          { key: 'status_name', label: 'status', class: 'text-center' },
          { key: 'employee_name', label: 'employee' },
          { key: 'active', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeShortFormatter }
        ]
      }
    }
  },

  computed: {
    modalItemTitle () {
      return this.state.editing ? 'Edit Item' : 'Add Item'
    },

    filterTemplates () {
      return [{ text: 'All', value: 'All', categories: [] }].concat(this.list.filter.templates)
    },

    filterStatuses () {
      return [{ text: 'All', value: 'All' }].concat(this.list.filter.statuses)
    }
  },

  watch: {

    'filter.template' (template) {
      if (template) {
        const position = this.list.filter.templates.findIndex(finder => finder.value === template)
        if (position >= 0) {
          this.list.filter.categories = this.list.filter.templates[position].categories
        } else {
          this.list.filter.categories = []
        }
      }
    },

    'selected.categories' () {
      this.onRefreshTableItems()
    },

    'selected.supplier' (supplier) {
      this.item.supplier = supplier?.id || null
    },

    'selected.brand' (brand) {
      this.item.brand = brand?.id || null
    },

    'itemStatus.status' (itemStatus) {
      const status = this.list.item.statuses.find(status => status.value === itemStatus)
      if (status) {
        this.itemStatus.costable = status.costable
      } else {
        this.itemStatus.costable = false
      }
    }
  },

  mounted () {
    core.index()
    this.getFilterTemplateLists().then(() => {
      this.getFilterStatusesLists()
    })
  },

  methods: {
    async myProvider (ctx) {
      return await AdminItemService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_template: this.filter.template,
          filter_status: this.filter.status,
          filter_categories: this.selected.categories.map(category => category.category_id).sort()
        })
      ).then(({ data }) => {
        this.tableItems.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    async getFilterTemplateLists () {
      this.fetching.filter.templates = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getTemplates().then(({ data }) => {
          this.list.filter.templates = data.map(({ id, template_name, compositions }) => ({
            value: id,
            text: template_name,
            categories: compositions.filter(composition => composition.is_parent === 0)
          }))
          this.fetching.filter.templates = this.state.busy = false
          resolve(data)
        })
      })
    },

    async getFilterStatusesLists () {
      this.fetching.filter.statuses = this.state.busy = true
      await SharedListService.getStatuses().then(({ data }) => {
        this.list.filter.statuses = data.map(({ id, status_name }) => ({ value: id, text: status_name }))
        this.fetching.filter.statuses = this.state.busy = false
      })
    },

    async getItemTemplateLists (query = '') {
      this.fetching.item.templates = this.state.busy = true

      if (this.$refs.formItem) {
        this.$refs.formItem.reset()
      }

      return new Promise(resolve => {
        SharedListService.getTemplates(query).then(({ data }) => {
          this.list.item.templates = data.map(({ id, template_name, compositions }) => ({
            text: template_name,
            value: id,
            compositions: compositions
          }))
          this.fetching.item.templates = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getItemTypeLists (template) {
      this.item.type = null
      this.list.item.types = []

      if (this.$refs.formItem) {
        this.$refs.formItem.reset()
      }

      this.fetching.item.types = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getTypes(`template=${template}`).then(({ data }) => {
          this.list.item.types = data.map(({ id, type_name }) => ({
            text: type_name,
            value: id
          }))
          this.fetching.item.types = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getItemCategoryLists (type) {
      this.item.category = null
      this.list.item.categories = []

      if (this.$refs.formItem) {
        this.$refs.formItem.reset()
      }

      this.fetching.item.categories = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getCategories(`template=${this.item.template}&type=${type}&parent=1`).then(({ data }) => {
          this.list.item.categories = data.map(({ id, category_name }) => ({
            text: category_name,
            value: id
          }))
          this.fetching.item.categories = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getItemSupplierLists () {
      this.fetching.item.suppliers = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getSuppliers().then(({ data }) => {
          this.list.item.suppliers = data.map(({ id, supplier_name, supplier_address }) => ({ id, supplier_name, supplier_address }))
          this.fetching.item.suppliers = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getItemBrandLists () {
      this.fetching.item.brands = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getBrands().then(({ data }) => {
          this.list.item.brands = data.map(({ id, brand_name }) => ({ id, brand_name }))
          this.fetching.item.brands = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getItemStatusLists () {
      this.fetching.item.statuses = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getStatuses().then(({ data }) => {
          this.list.item.statuses = data.map(({ id, status_name, costable }) => ({
            text: status_name,
            value: id,
            costable: costable
          }))
          this.fetching.item.statuses = this.state.busy = false
          resolve(true)
        })
      })
    },

    onRefreshTableItems () {
      this.$refs.tableItems.refresh()
    },

    onShowAddFormItem () {
      this.state.editing = false

      this.item.id = null
      this.item.template = null
      this.item.template_name = null
      this.item.type = null
      this.item.type_name = null
      this.item.category = null
      this.item.category_name = null
      this.item.supplier = null
      this.item.brand = null
      this.item.item_code = null
      this.item.item_name = null
      this.item.serial_number = null
      this.item.purchase_date = null
      this.item.original_cost = 0
      this.item.specifications = null
      this.item.device_name = null
      this.item.mac_address = null
      this.item.sap_asset_code = null
      this.item.active = 0

      this.selected.supplier = null
      this.selected.brand = null

      this.getItemTemplateLists().then(() => {
        this.getItemSupplierLists().then(() => {
          this.getItemBrandLists()
        })
      })

      this.$bvModal.show('modal-item')
    },

    onShowEditFormItem (item) {
      this.state.editing = true

      this.item.id = item.id
      this.item.item_code = item.item_code
      this.item.item_name = item.item_name
      this.item.serial_number = item.serial_number
      this.item.purchase_date = item.purchase_date
      this.item.original_cost = item.original_cost
      this.item.specifications = item.specifications
      this.item.device_name = item.device_name
      this.item.mac_address = item.mac_address
      this.item.sap_asset_code = item.sap_asset_code
      this.item.active = item.active

      this.selected.supplier = null
      this.selected.brand = null

      this.getItemTemplateLists(`type=${item.type_id}&category=${item.category_id}`).then(() => {
        this.item.template = item.template_id
        this.item.template_name = item.template_name

        this.getItemTypeLists().then(() => {
          this.item.type = item.type_id
          this.item.type_name = item.type_name

          this.getItemCategoryLists().then(() => {
            this.item.category = item.category_id
            this.item.category_name = item.category_name
          })
        })

        this.getItemSupplierLists().then(() => {
          this.item.supplier = item.supplier_id
          this.selected.supplier = {
            id: item.supplier.id,
            supplier_name: item.supplier.supplier_name,
            supplier_address: item.supplier.supplier_address
          }

          this.getItemBrandLists().then(() => {
            this.item.brand = item.brand_id
            this.selected.brand = {
              id: item.brand.id,
              brand_name: item.brand.brand_name
            }
          })
        })

        this.$bvModal.show('modal-item')
      })
    },

    async onValidateFormItem (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formItem.validate().then(async success => {
        if (success) {
          let text = 'Do you really want to create item?'

          if (this.state.editing) {
            text = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: text,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              if (this.state.editing) {
                return this.onPutFormItem()
              } else {
                return this.onPostFormItem()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onPostFormItem () {
      return new Promise(resolve => {
        AdminItemService.post(this.item).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-item')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tableItems.refresh()
          })
        }).catch(error => {
          this.state.busy = false

          this.$refs.formItem.setErrors(error.message)
          resolve(error)
        })
      })
    },

    async onPutFormItem () {
      return new Promise(resolve => {
        AdminItemService.put(this.item).then(({ data }) => {
          this.$bvModal.hide('modal-item')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tableItems.localItems, { id: data.item.id })
            row.id = data.item.id
            row.category = data.item.category
            row.category_id = data.item.category_id
            row.category_name = data.item.category_name
            row.supplier = data.item.supplier
            row.supplier_id = data.item.supplier_id
            row.supplier_name = data.item.supplier_name
            row.brand = data.item.brand
            row.brand_id = data.item.brand_id
            row.brand_name = data.item.brand_name
            row.item_code = data.item.item_code
            row.item_name = data.item.item_name
            row.serial_number = data.item.serial_number
            row.purchase_date = data.item.purchase_date
            row.original_cost = data.item.original_cost
            row.specifications = data.item.specifications
            row.device_name = data.item.device_name
            row.mac_address = data.item.mac_address
            row.sap_asset_code = data.item.sap_asset_code
            row.status = data.item.status
            row.status_id = data.item.status_id
            row.status_name = data.item.status_name
            row.status_cost = data.item.status_cost
            row.remarks = data.item.remarks
            row.active = data.item.active
            row.updated_at = data.item.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formItem.setErrors(error.message)
          resolve(error)
        })
      })
    },

    onShowEditFormItemStatus (item) {
      this.state.editing = true

      this.itemStatus.id = item.id
      this.itemStatus.serial_number = item.serial_number
      this.itemStatus.original_cost = item.original_cost

      this.itemStatus.template_name = item.template_name
      this.itemStatus.type = item.type_id
      this.itemStatus.type_name = item.type_name
      this.item.category = item.category_id
      this.itemStatus.category_name = item.category_name
      this.itemStatus.item_code = item.item_code
      this.itemStatus.item_name = item.item_name

      this.itemStatus.remarks = item.remarks

      this.getItemStatusLists().then(() => {
        this.itemStatus.status = item.status_id
        this.itemStatus.status_cost = 0.00
        this.$bvModal.show('modal-item-status')
      })
    },

    async onValidateFormItemStatus (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formItemStatus.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Do you really want to save changes?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              return this.onPutFormItemStatus()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onPutFormItemStatus () {
      return new Promise(resolve => {
        AdminItemService.status(this.itemStatus).then(({ data }) => {
          this.$bvModal.hide('modal-item-status')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tableItems.localItems, { id: data.item.id })
            row.category = data.item.category
            row.category_id = data.item.category_id
            row.category_name = data.item.category_name
            row.supplier = data.item.supplier
            row.supplier_id = data.item.supplier_id
            row.supplier_name = data.item.supplier_name
            row.brand = data.item.brand
            row.brand_id = data.item.brand_id
            row.brand_name = data.item.brand_name
            row.item_code = data.item.item_code
            row.item_name = data.item.item_name
            row.serial_number = data.item.serial_number
            row.purchase_date = data.item.purchase_date
            row.original_cost = data.item.original_cost
            row.specifications = data.item.specifications
            row.device_name = data.item.device_name
            row.mac_address = data.item.mac_address
            row.status = data.item.status
            row.status_id = data.item.status_id
            row.status_name = data.item.status_name
            row.status_cost = data.item.status_cost
            row.remarks = data.item.remarks
            row.active = data.item.active
            row.updated_at = data.item.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formItemStatus.setErrors(error.message)
          resolve(error)
        })
      })
    }
  }
}
</script>
